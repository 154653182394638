import { render, staticRenderFns } from "./ImageCarousel.vue?vue&type=template&id=08e13c3e&scoped=true&"
import script from "./ImageCarousel.vue?vue&type=script&lang=js&"
export * from "./ImageCarousel.vue?vue&type=script&lang=js&"
import style0 from "./ImageCarousel.vue?vue&type=style&index=0&id=08e13c3e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08e13c3e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VCarousel,VCarouselItem,VIcon,VImg})
