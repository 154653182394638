<template>
  <image-carousel :ad="ad" class="secondary-lighten rounded mb-2" />
</template>

<script>
  import Ads from '@/services/classes/Ads.js'
  import ImageCarousel from '@/components/ads/ImageCarousel.vue'

  export default {
    name: 'InterstitialCreativePreview',
    components: { ImageCarousel },
    props: {
      ad: {
        type: Ads,
        required: true
      }
    }
  }
</script>
