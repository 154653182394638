<template>
  <v-carousel
    cycle
    hide-delimiter-background
    :height="height"
    :show-arrows="slides.length > 1"
    show-arrows-on-hover
    :hide-delimiters="slides.length <= 1"
  >
    <v-carousel-item v-for="(slide, index) in slides" :key="index">
      <slot :slide="slide" :index="index">
        <a
          v-if="isTypeVideo(slide)"
          class="d-flex justify-center preview-container"
          target="_blank"
          :href="slide"
        >
          <v-icon size="50" color="primary"> mdi-file-video-outline </v-icon>
        </a>
        <v-img v-if="!isTypeVideo(slide)" loading="lazy" class="responsive-size" contain :src="slide" />
      </slot>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
  import Ads from '@/services/classes/Ads.js'

  export default {
    name: 'ImageCarousel',
    props: {
      ad: {
        type: Ads,
        required: true
      },
      height: {
        type: [Number, String],
        default: '190'
      }
    },
    computed: {
      slides() {
        return this.ad.model.creatives.map((creative) => creative.image?.src || creative.package?.documentUrl)
      },
      isTypeVideo() {
        return (url) => {
          const type = url.slice(-3)

          return type === 'mp4'
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep .v-responsive__content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ::v-deep .responsive-size {
    max-width: 100%;
    max-height: 100%;
  }
  .preview-container {
    border-radius: 5px;
    text-decoration: none;
    border: 2px solid var(--v-primary-base);
    cursor: pointer;
    height: 90%;
    width: 90%;
  }
</style>
